import {jsPDF} from 'jspdf'
import "./Gourmet Le French-normal"
import "./OpenSans-bold"
import "./OpenSans-normal"
import logo from './logo'
import jQuery from "jquery";

// Header variables
const headerFontSize = 48
const initialHeaderOffset = 32
const spaceBetweenHeaderLines = 20

// Matches variables
const spaceBetweenDateAndTeams = 11
const matchesPerPage = 6
const spaceBetweenMatches = 30
const initialMatchOffset = 70
const dateFontSize = 20
const teamFontSize = 26

// Footer variables
const imageSize = 30
const marginToBottom = 10

const addFooters = (doc: jsPDF) => {
    const pageCount = doc.getNumberOfPages()
    for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        doc.addImage(
            logo,
            "png",
            (doc.internal.pageSize.width - imageSize) / 2,
            doc.internal.pageSize.height - imageSize - marginToBottom,
            imageSize,
            imageSize)
    }
}
const addHeaders = (doc: jsPDF) => {
    const pageCount = doc.getNumberOfPages()
    for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        doc.setFontSize(headerFontSize)
        doc.setFont("Gourmet Le French", "normal")

        doc.text("KOMMENDE", doc.internal.pageSize.width / 2, initialHeaderOffset, {align: 'center'})
        doc.text("FODBOLD KAMPE", doc.internal.pageSize.width / 2, initialHeaderOffset + spaceBetweenHeaderLines, {align: 'center'})
    }

}

const offsets = (new Array(matchesPerPage).fill(initialMatchOffset)).reduce((memo, _, i) => {
    memo.push(initialMatchOffset + spaceBetweenMatches * i)
    return memo
}, [])

const addDates = (doc: jsPDF, data: [string, string][]) => {
    data.forEach(([date, teams], i) => {
        const offset = offsets[i % offsets.length]
        if(i !== 0 && offset === offsets[0]){
            doc.addPage()
        }
        
        // Date
        doc.setFontSize(dateFontSize)
        doc.setFont("OpenSans", "normal")
        doc.text(date.toLocaleUpperCase(), doc.internal.pageSize.width / 2, offset, {align: "center"})

        // Teams
        doc.setFontSize(teamFontSize)
        doc.setFont("OpenSans", "bold")
        doc.text(teams.toLocaleUpperCase(), doc.internal.pageSize.width / 2, offset + spaceBetweenDateAndTeams, {align: "center"})
    })
}

const getData = () : [string, string][] => {
    const dates = jQuery('.dateInput:visible').map(function(_, b){
        return b.value
    }).toArray()
    const teams = jQuery('.teamInput:visible').map(function(a, b){
        return b.value
    }).toArray()

    return dates.map(function(date, i) {
        return [date, teams[i]];
    });

}

const generatePdf = () => {    
    const doc = new jsPDF()
    const data = getData()

    addDates(doc, data)
    addFooters(doc)
    addHeaders(doc)

    doc.save("event-poster.pdf")
}


jQuery(() => {
    jQuery('#count').text(matchesPerPage)
    const copyRow = jQuery("#copy");
    jQuery(document).on("click", ".delete", function(){
        console.log("a")
        jQuery(this).parents("tr").remove()
    })
    jQuery('#generate').click(generatePdf)
    jQuery("#addRow").click(() => {
        console.log("C")
        const newRow = copyRow.clone().removeAttr('id')
        jQuery("table").append(newRow)
    })
})